<messages>["./CreditLimit"]</messages>

<template>
  <base-layout mw0>
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          <div>
            <div class="text-h5">
              {{ $t (`label.title`) }}
            </div>
            <p class="cgwng-subheading">
              {{ $t (`label.subTitle`) }}
            </p>
          </div>
        </v-card-title>
        <v-card-text>
          <form>
            <v-row>
              <v-col cols="12" sm="6">
                <client-select
                  v-model="limit.clientId"
                  for-accounting
                  type="month"
                  class="required"
                  :v="$v.limit.clientId"
                  :v-messages="{required: $t ('label.error.clientRequired')}"/>
              </v-col>
              <v-col cols="12" sm="6">
                <date-picker-field
                  v-model="endDate"
                  class="required"
                  :label="$t('label.endDate')"
                  :allowed-dates="future"
                  :error-messages="validationErrors(
                    'limit.endDate',
                    {
                      required: 'label.error.dateRequired',
                    })"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="limit.temporaryLimit"
                  class="required"
                  type="number"
                  :reverse="currencySymbol[1]"
                  step="0.01"
                  :label="$t ('label.temporaryLimit')"
                  :error-messages="validationErrors(
                    'limit.temporaryLimit',
                    {
                      decimal: 'label.error.decimal',
                      required: 'label.error.limitRequired',
                      greaterZero: 'label.error.greaterZero'
                    })">
                  <template slot="append">
                    {{ currencySymbol[0] }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="limit.comment"
                  :label="$t ('label.comment')"/>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="reset">
            {{ $t ('general.button.reset') }}
          </v-btn>
          <v-btn :loading="isLoading" color="primary" @click="submit">
            {{ $t ('general.button.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapActions, mapMutations} from 'vuex'
  import {required, decimal} from 'vuelidate/lib/validators'

  import {formatDateShort} from '@/app/core/mixins/DatetimeHelper'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import ClientSelect from '@/app/core/components/ClientSelect'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import DatePickerField, {future}
    from '@/app/core/components/DatePickerField'

  const baseLimit = () => {
    const current = new Date ()
    const d = new Date (
      formatDateShort (new Date (new Date ().setUTCDate (current.getUTCDate () + 11)).getTime (), true)
    )
    return {
      clientId: null,
      endDate: d.getTime (),
      temporaryLimit: 1,
      comment: null
    }
  }

  export default {
    components: {
      BaseLayout,
      ClientSelect,
      DatePickerField
    },

    mixins: [validationMixins],

    validations: {
      limit: {
        clientId: {required},
        temporaryLimit: {
          required,
          decimal,
          greaterZero: v => Number.parseFloat (v) > 0
        },
        endDate: {required}
      }
    },

    data () {
      return {
        limit: baseLimit (),
        isLoading: false,
        currency: '',
        isLoadingCurrency: false,
        savedCurrency: {},
        idWasDefined: false
      }
    },

    computed: {
      endDate: {
        get () {
          return formatDateShort (this.limit.endDate, true)
        },
        set (newVal) {
          const newDate = new Date (newVal).getTime ()
          this.limit.endDate = Number.isNaN (newDate) ? null : newDate
        }
      },

      currencySymbol () {
        return this.getSymbol (this.currency)
      }
    },

    watch: {
      'limit.clientId' () {
        this.$router.replace ({
          name: this.$route.name,
          query: {...this.$route.query, id: this.limit.clientId}
        })

        this.loadCurrency ()
      },
      '$route.query.id': {
        handler (newVal) {
          if (newVal) {
            const newId = Number.parseInt (this.$route.query.id)
            this.limit.clientId = Number.isNaN (newId) ? null : newId
          }
        },
        immediate: true
      }
    },

    created () {
      if (this.$route.query.id) {
        this.idWasDefined = true
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      future,

      submit () {
        if (!this.$v.$invalid) {
          this.isLoading = true
          this.fetchData ({
            op: 'account/set-temp-credit-limit',
            params: {
              data: this.limit
            },
            cb: () => {
              this.displaySuccessMessage (this.$t ('label.success'))
              if (this.idWasDefined) {
                this.$router.go (-1)
              } else {
                this.$router.push ({name: 'creditlimit.list'})
              }
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        } else {
          this.$v.$touch ()
        }
      },

      loadCurrency () {
        if (!this.limit.clientId) {
          this.currency = ''
          return
        }

        if (!this.savedCurrency[this.limit.clientId]) {
          this.isLoadingCurrency = true
          this.fetchData ({
            op: 'client/data/default-currency',
            params: {
              id: this.limit.clientId
            },
            cb: (data) => {
              this.savedCurrency[this.limit.clientId] = data.string
              this.currency = data.string
            },
            cbFinal: () => {
              this.isLoadingCurrency = false
            }
          })
        } else {
          this.currency = this.savedCurrency[this.limit.clientId]
        }
      },

      reset () {
        this.limit = baseLimit ()
      }
    }
  }
</script>
