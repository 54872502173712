var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"mw0":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t ("label.title"))+" ")]),_c('p',{staticClass:"cgwng-subheading"},[_vm._v(" "+_vm._s(_vm.$t ("label.subTitle"))+" ")])])]),_c('v-card-text',[_c('form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('client-select',{staticClass:"required",attrs:{"for-accounting":"","type":"month","v":_vm.$v.limit.clientId,"v-messages":{required: _vm.$t ('label.error.clientRequired')}},model:{value:(_vm.limit.clientId),callback:function ($$v) {_vm.$set(_vm.limit, "clientId", $$v)},expression:"limit.clientId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('date-picker-field',{staticClass:"required",attrs:{"label":_vm.$t('label.endDate'),"allowed-dates":_vm.future,"error-messages":_vm.validationErrors(
                  'limit.endDate',
                  {
                    required: 'label.error.dateRequired',
                  })},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"type":"number","reverse":_vm.currencySymbol[1],"step":"0.01","label":_vm.$t ('label.temporaryLimit'),"error-messages":_vm.validationErrors(
                  'limit.temporaryLimit',
                  {
                    decimal: 'label.error.decimal',
                    required: 'label.error.limitRequired',
                    greaterZero: 'label.error.greaterZero'
                  })},model:{value:(_vm.limit.temporaryLimit),callback:function ($$v) {_vm.$set(_vm.limit, "temporaryLimit", $$v)},expression:"limit.temporaryLimit"}},[_c('template',{slot:"append"},[_vm._v(" "+_vm._s(_vm.currencySymbol[0])+" ")])],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t ('label.comment')},model:{value:(_vm.limit.comment),callback:function ($$v) {_vm.$set(_vm.limit, "comment", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"limit.comment"}})],1)],1)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t ('general.button.reset'))+" ")]),_c('v-btn',{attrs:{"loading":_vm.isLoading,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t ('general.button.submit'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }